import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
  const ComLinks = [
    {
      name: "Company",
      links: [
        { text: "Blog", href: "/blog" },
        { text: "Career", href: "/career" },
        { text: "Pricing", href: "/pricing" },
      ],
    },
    {
      name: "Resources",
      links: [
        { text: "Documentation", href: "/documentation" },
        { text: "Papers", href: "/Papers" },
        { text: "Press Conferences", href: "/press-conferences" },
      ],
    },
    {
      name: "Legal",
      links: [
        { text: "Terms of Service", href: "/terms-of-service" },
        { text: "Privacy Policy", href: "/privacy-policy" },
        { text: "Data Processing", href: "/data-processing" },
      ],
    },
  ];

  const SocLink = [
    { icon: <BsInstagram />, href: "" },
    { icon: <BsTwitter />, href: "" },
    { icon: <FaFacebookF />, href: "" },
  ];

  return (
    <Container className="aj-container_fluid">
      <div className="content">
        <div className="top">
          <div className="left">
            <div className="logo">
              <img src="/logos/logo_light.svg" alt="logo" />
            </div>
            <p>
              Lorem ipsum dolor sit amet consectetur. Augue pellentesque
              vestibulum vehicula netus volutpat velit massa.
            </p>
          </div>
          <div className="right">
            {React.Children.toArray(
              ComLinks.map((linkRow) => (
                <div className="link_row">
                  <h4>{linkRow.name}</h4>
                  {React.Children.toArray(
                    linkRow.links.map((link) => (
                      <Link to={link.href} className="link">
                        {link.text}
                      </Link>
                    ))
                  )}
                </div>
              ))
            )}
          </div>
        </div>
        <hr />
        <div className="bottom">
          <small>
            &copy; 2023-{new Date().getFullYear()} AbroadMates Inc. All rights
            reserved. developed by{" "}
            <a
              href="http://ahjim.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Akhlak
            </a>
          </small>
          <div className="links">
            {React.Children.toArray(
              SocLink.map((link) => (
                <>
                  <a href={link.href} target="_blank" rel="noopener noreferrer">
                    {link.icon}
                  </a>
                </>
              ))
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
const Container = styled.footer`
  background: #000;
  padding: 50px 24px;
  margin: 0 auto;
  & > .content {
    width: min(1200px, 100%);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > .top {
      display: flex;
      gap: 24px;
      padding-bottom: 24px;
      @media (max-width: 780px) {
        flex-direction: column;
      }
      & > .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
        & > .logo {
          overflow: hidden;
          /* position: relative; */
          /* display: block; */
          width: min(96.828px, 8rem);
          aspect-ratio: 96.828/36;
          z-index: 0;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        & > p {
          width: min(100%, 362px);
          color: #fff;
        }
      }
      & > .right {
        flex: 2;
        display: flex;
        gap: 48px;
        justify-content: space-around;
        flex-wrap: wrap;
        @media (max-width: 780px) {
          justify-content: space-between;
        }
        & > .link_row {
          display: flex;
          flex-direction: column;
          gap: 16px;
          & > h4 {
            color: var(--aj-primary);
          }
          & > .link {
            color: var(--aj-gray-100);
            text-decoration: none;
          }
        }
      }
    }
    & > .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      color: #fff;
      padding-top: 12px;
      @media (max-width: 580px) {
        flex-direction: column;
      }
      & > small > a {
        color: var(--aj-primary);
      }
      & > .links {
        display: flex;
        gap: 24px;
        & > a {
          color: var(--aj-gray-100);
        }
      }
    }
  }
`;
