import React from "react";
import styled from "styled-components";

export default function BottomGraphics() {
  return (
    <Container className="image">
      <img src="/images/london.png" alt="am subtle background" />
    </Container>
  );
}

const Container = styled.div`
  width: min(1440px, 100%);
  padding: 0 24px;
  & > img {
    width: 100%;
    height: auto;
  }
`;
