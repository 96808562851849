import React from "react";
import { styled } from "styled-components";
import { BsChevronRight } from "react-icons/bs";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

export default function Testimonial() {
  const Records = [
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
    },
    {
      text: "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis. Aliquam ultrices eget dolor urna condimentum. Amet purus blandit nulla sed diam auctor viverra.",
      name: "Christopher Gavigan",
      position: "Founder, X Media.com",
      avatar: "/icons/info.png",
    },
  ];
  return (
    <Container className="aj-container_lg">
      <h2>What Our Mates Are Saying</h2>
      <div className="content_slide">
        <Splide
          hasTrack={false}
          options={{
            type: "loop",
            rewind: true,
            width: "90%",
            gap: "32px",
            pagination: true,
            perPage: 3,
            perMove: 1,
            breakpoints: {
              900: {
                perPage: 2,
              },
              600: {
                perPage: 1,
              },
            },
          }}
        >
          <SplideTrack>
            {Array.isArray(Records) &&
              Records &&
              Records.length > 0 &&
              React.Children.toArray(
                Records.map((item) => (
                  <SplideSlide>
                    <div className="cnt">
                      <div className="card">
                        <div className="card_media">
                          <img src={item.avatar} alt={item.name} />
                        </div>
                        <div className="card_content">
                          <p className="name">{item.name}</p>
                          <small>{item.position}</small>
                          <p>{item.text}</p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                ))
              )}
          </SplideTrack>
          <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
              <BsChevronRight />
            </button>
            <button className="splide__arrow splide__arrow--next">
              <BsChevronRight />
            </button>
          </div>
        </Splide>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 56px;
  & > h2 {
    text-align: center;
  }
  & > .content_slide {
    width: 100%;
    position: relative;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    .cnt {
      padding-top: 50px;
      & > .card {
        display: flex;
        flex-direction: column;
        border: 1px solid var(--aj-gray-100);
        border-radius: 8px;
        padding: 0px 18px 24px;
        gap: 32px;
        margin: 0 auto;
        text-align: center;
        & > .card_media {
          flex: 1;
          width: 96px;
          aspect-ratio: 1/1;
          display: flex;
          margin: 0 auto;
          margin-top: -50px;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        & > .card_content {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          & > small {
            line-height: 100%;
          }
          & > .name {
            line-height: 100%;
            color: red;
          }
        }
      }
    }
    .splide__pagination {
      gap: 2;
      bottom: -20px;
    }
    .splide__pagination__page {
      transform: scale(1);
    }
    .splide__pagination__page.is-active {
      background-color: var(--aj-primary);
      transform: scale(1.4);
    }
    .splide__arrow {
      font-size: 30px;
      background-color: #fff;
      /* border: 5px solid var(--aj-gray-100); */
      background-color: var(--aj-red-shade);
      padding: 16px;
      opacity: 1;
      @media (max-width: 580px) {
        padding: 0;
        font-size: 20px;
      }
      & > svg {
        fill: var(--aj-primary);
      }
      &--prev {
        left: -1.5rem;
      }
      &--next {
        right: -1.5rem;
      }
    }
  }
`;
