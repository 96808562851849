import React from "react";
import { styled } from "styled-components";
import { FiArrowRight } from "react-icons/fi";

export default function HowHelp() {
  const Cards = [
    {
      icon: "/icons/info.png",
      text: "1:1 Information Session",
      href: "",
    },
    {
      icon: "/icons/help.png",
      text: "Specific Help",
      href: "",
    },
    {
      icon: "/icons/whole.png",
      text: "Whole Application (A-Z)",
      href: "",
    },
    {
      icon: "/icons/start.png",
      text: "Just Getting Started",
      href: "",
    },
  ];
  return (
    <Container className="aj-container_lg">
      <h2>How can we help you?</h2>
      <div className="content">
        {React.Children.toArray(
          Cards.map((card) => (
            <div
              className="card"
              role="button"
              onClick={() => card.href && window.open(card.href, "_self")}
            >
              <div className="icon">
                <img src={card.icon} alt={card.text} />
              </div>
              <p className="aj-subtitle">{card.text}</p>
              <span className="h4">
                <FiArrowRight />
              </span>
            </div>
          ))
        )}
      </div>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 56px;
  & > h2 {
    text-align: center;
  }
  & > .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    @media (max-width: 580px) {
      grid-template-columns: 1fr;
    }
    & > .card {
      padding: 32px 48px;
      display: flex;
      align-items: center;
      gap: 24px;
      border-radius: 8px;
      border: 2px solid var(--aj-gray-200);
      @media (max-width: 780px) {
        padding: 24px;
        gap: 16px;
      }
      @media (max-width: 580px) {
        padding: 32px 24px;
        flex-direction: column;
      }
      & > .icon {
        width: 48px;
        height: 48px;
        aspect-ratio: 1/1;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > span {
        margin-left: auto;
        color: var(--aj-primary);

        @media (max-width: 580px) {
          display: none;
        }
      }
    }
  }
`;
