import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoSearchSharp } from "react-icons/io5";
import { PiSlidersHorizontalBold } from "react-icons/pi";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../utils/firebase";
import Cards from "../components/Cards";
import BottomGraphics from "../components/BottomGraphics";

export default function MentorsGridPage() {
  const [SearchText, setSearchText] = useState();
  const [Data, setData] = useState([]);
  const [UsableData, setUsableData] = useState();
  const [Loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    try {
      async function dataCallSeries() {
        let data = [];
        const q = query(
          collection(db, "users"),
          where("userType", "==", "mentor")
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
        return data;
      }

      Promise.resolve(dataCallSeries()).then((value) => {
        setData(value);
        setUsableData(value);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (Data && Array.isArray(Data) && Data.length > 0) {
      if (SearchText && SearchText.length > 0) {
        let data = [...Data];
        let some = data.filter((el) =>
          el?.displayName?.toLowerCase().includes(SearchText?.toLowerCase())
        );
        setUsableData(some);
      } else {
        setUsableData(Data);
      }
    }
  }, [SearchText]);

  return (
    <Container>
      <section className="hero">
        <img src="/images/mates-hero.webp" alt="mates hero banner" />
      </section>
      <section className="mentors">
        <h1>
          Search Your <span>Abroadmates</span>
        </h1>

        <div className="filtration">
          <div className="search">
            <span>
              <IoSearchSharp />
            </span>
            <input
              type="text"
              name="Search"
              id="Search"
              value={SearchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="filter_trigger">
            <PiSlidersHorizontalBold />
          </div>
        </div>

        <div className="grid">
          {UsableData &&
            Array.isArray(UsableData) &&
            UsableData.length > 0 &&
            React.Children.toArray(
              UsableData.map((item) => (
                <Cards Data={item} id={item?.id} transfer={true} />
              ))
            )}
        </div>
      </section>
      <BottomGraphics />
    </Container>
  );
}

const Container = styled.main`
  & > .hero {
    width: min(1440px, 100%);
    aspect-ratio: 1440/717;
    margin: 0 auto;
    overflow: hidden;
    & > img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  & > .mentors {
    width: min(1320px, 100%);
    padding: 48px 24px;
    margin: 0 auto;
    & > h1 {
      font-size: 2.5rem;
      font-weight: 700;
      text-align: center;
      & > span {
        color: var(--aj-primary);
      }
    }
    & > .filtration {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin: 24px 0;
      & > .search {
        padding: 4px;
        background-color: var(--aj-primary);
        border: 1.2px solid var(--aj-dark);
        border-radius: 8px;
        display: flex;
        align-self: center;
        gap: 4px;
        & > span {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--aj-white-100);
          border-radius: 4px;
          padding: 2px;
          color: var(--aj-primary);
        }
        & > input {
          background-color: transparent;
          border: none;
          outline: none;
          color: var(--aj-white-100);
          &:focus {
            box-shadow: none;
          }
        }
      }
      & > .filter_trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--aj-primary);
        padding: 6px;
        color: var(--aj-white-100);
        border: 1.2px solid var(--aj-dark);
        border-radius: 8px;
      }
    }
    & > .grid {
      padding-top: 24px;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 670px) {
        grid-template-columns: 1fr;
      }
    }
  }
`;
