import React, { useState } from "react";
import { styled } from "styled-components";
import { BsPlusLg } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";

export default function Summary({ title, details = "", children }) {
  const [Show, setShow] = useState(false);
  return (
    <Container onClick={() => setShow(!Show)} role="button">
      <div className="title">
        <h4>{title}</h4>
        <div className="icon">{Show ? <AiOutlineMinus /> : <BsPlusLg />}</div>
      </div>
      {details && Show && <p>{details}</p>}
      {Show && children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 30px 50px;
  border: 2px solid var(--aj-gray-200);
  border-radius: 8px;
  @media (max-width: 580px) {
    padding: 24px 16px;
  }
  & > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .icon {
      font-size: 36px;
      color: var(--aj-primary);
    }
  }
`;
