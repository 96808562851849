import React, { useState } from "react";
import { styled } from "styled-components";

export default function Blog() {
  const [Blog, setBlog] = useState([
    {
      img: "/images/hero-bg-xs.webp",
      title: "Study abroad: experiences of studying in Asia, North America...",
      details:
        "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis.",
      link: "",
    },
    {
      img: "/images/hero-bg-xs.webp",
      title: "Study abroad: experiences of studying in Asia, North America...",
      details:
        "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis.",
      link: "",
    },
    {
      img: "/images/hero-bg-xs.webp",
      title: "Study abroad: experiences of studying in Asia, North America...",
      details:
        "Lorem ipsum dolor sit amet consectetur. At dui gravida fermentum aliquam id tellus lobortis pellentesque sagittis.",
      link: "",
    },
  ]);
  return (
    <Container className="aj-container_lg">
      <h2>Most Read Blogs</h2>
      <div className="contents">
        {Array.isArray(Blog) &&
          Blog &&
          Blog.length > 0 &&
          React.Children.toArray(
            Blog.map((item) => (
              <div className="card">
                <div className="card_media">
                  <img src={item.img} alt={item.title} />
                </div>
                <div className="card_content">
                  <h5 className="aj-subtitle">{item.title}</h5>
                  <p>{item.details}</p>
                  <div className="card_button">
                    <a href={item.link} className="aj-btn-secondary-outline">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))
          )}
      </div>
      <div className="buttons">
        <a
          href="http://"
          className="aj-btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          See All Blogs
        </a>
      </div>
    </Container>
  );
}
const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 56px;
  & > h2 {
    text-align: center;
  }
  & > .contents {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    gap: 16px;
    & > .card {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--aj-gray-100);
      border-radius: 8px;
      overflow: hidden;
      width: clamp(300px, 33%, 389px);
      min-width: 250px;
      & > .card_media {
        width: 100%;
        aspect-ratio: 389/224;
        overflow: hidden;
        & > img {
          width: 105%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      & > .card_content {
        padding: 24px 16px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        & > .card_button {
          display: flex;
          & > a {
            margin-top: auto;
            display: flex;
          }
        }
      }
    }
  }
  & > .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
    & > * {
      padding: 20px 24px;
    }
  }
`;
